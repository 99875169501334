
import React from 'react';

import { ContactContext } from '../ContactContext.js';

export default () => (
  <ContactContext.Consumer>
    { (contact) => (
      <section id="contact-box">
        <article>
          <h3>¿Interesado en el Servicio?</h3>
          <p>Si estás interesado en nuestros servicios ponte en contacto con nosotros.</p>
          <button onClick={ contact.showContactModal } className="button button-red">CONTACTAR</button>
        </article>
      </section>
    )}
  </ContactContext.Consumer>
);