
import React from 'react';
import Navbar from '../components/Navbar';
import '../sass/index.scss';
import logo from '../../static/img/logo.svg';

import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import '../fa.js';
import favicon from '../../static/img/favicon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ContactContext } from '../ContactContext.js';
import ContactModal from '../components/ContactModal.js';

if (typeof window !== 'undefined') {
  require('smooth-scroll')('a[href*="#"]', {
    offset: 120
  });
}

export default class extends React.Component {

  state = {
    isShowingContactModal: false,
  };

  showContactModal = (event) => {
    event.preventDefault();
    this.setState({isShowingContactModal: true});
  };

  hideContactModal = () => {
    this.setState({isShowingContactModal: false});
  };
  
  render() {
    return (
      <div id="application">
        <ContactContext.Provider
          value={ {
            isShowingContactModal: this.state.isShowingContactModal,
            showContactModal: this.showContactModal,
            hideContactModal: this.hideContactModal } }>
            
          <ContactContext.Consumer>
            { (props) => <ContactModal {...props} /> }
          </ContactContext.Consumer>
          <Helmet>
            <title>VITECH Soluciones Integrales - Rendiciones de Cuentas SIRC, Gestión Contable, Desarrollo de Software</title>
            <link rel="icon" type="image/png" href={ favicon } />
          </Helmet>
          <Navbar>
            <Link to="/">
              <img src={ logo } id="logo" alt="VITECH Logo" />
            </Link>
      
              <div className="top-contact">
                <a href="https://instagram.com/vitech.uy" rel="noopener noreferrer" target="_blank">
                  <FontAwesomeIcon className="contact-icon" size="lg" icon={['fab', 'instagram']} />
                </a>
                <a href="https://www.facebook.com/vitechuy/" rel="noopener noreferrer" target="_blank">
                  <FontAwesomeIcon className="contact-icon" size="lg" icon={['fab', 'facebook']} />
                </a>
                { /*
                <a href="https://instagram.com/vitech.uy" rel="noopener noreferrer" target="_blank">
                  <FontAwesomeIcon className="contact-icon" size="lg" icon={['fab', 'twitter']} />
                </a>
                */}
                <div className="separator"></div>
                <button onClick={ this.showContactModal } className="button button-red">CONTACTAR</button>
              </div>
          </Navbar>
          
          { this.props.children }
      
          <footer id="footer">
            <div className="container">
              <div>&copy; 2019 - Vitech Soluciones Integrales</div>
              <div></div>
              <div className="text-right">Enrique Tarigo 1335 Edificio Torre Libertad. Oficina 413. Teléfono: 092 391 963 - 2904 86 38</div>
            </div>
          </footer>
        </ContactContext.Provider>
      </div>
    );
  }
}
