
import React from 'react';

export default ({ id, title, subtitle = '' }) => (
  <div id={ id } className="section-heading">
    <div className="container">
      <h2>{ title }</h2>

    { subtitle && <p>{ subtitle }</p>}
    </div>
  </div>
);