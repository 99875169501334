
import { Link } from 'gatsby';
import React from 'react';
import background from '../../static/img/background.jpg';
import Coopel from '../../static/img/clients/coopel.png';
import DonBosco from '../../static/img/clients/donbosco.png';
import Horizonte from '../../static/img/clients/horizonte.png';
import ContactBox from '../components/ContactBox.js';
import Hero from '../components/Hero.js';
import Navbar from '../components/Navbar.js';
import SectionHeading from '../components/Sectionheading.js';
import Service from '../components/Service.js';
import sections from '../data/sections.js';
import DefaultLayout from '../layouts/Default.js';


const hero = {
  title: 'SOLUCIONES A MEDIDA',
  description: `Somos un equipo abocado a brindar soluciones integrales para la gestión organizacional,
                con énfasis en la utilización e integración de nuevas tecnologías.`,
  links: [
    <Link key="1" className="button button-red" to="#services">SERVICIOS</Link>,
  ],
  background
};

const links = [
  { label: "INICIO", href: "#application" },
  { label: "SERVICIOS", href: "#services"}
];

export default () => (
  <DefaultLayout>
    <Navbar kind="secondary" links={ links } />

    <Hero {...hero } />

    <SectionHeading id="services" title="Áreas" />

    <section className="services half">
      <div className="container">
        { sections.map((service, index) => (
          <Service key={ index } {...service} />
        )) }
      </div>
    </section>

    {/* <div id="clients">
      <div className="title">
        <h3>Confian en nosotros</h3>
      </div>
      <section id="client-list">
        <img src={ DonBosco } alt="Asociación Civil Don Bosco" title="Asociación Civil Don Bosco" />
        <img src={ Coopel } alt="Cooperativa de Producción Educativo Laboral" title="Cooperativa de Producción Educativo Laboral" />
        <img src={ Horizonte } alt="Escuela Horizonte" title="Escuela Horizonte" />
      </section>
        </div>*/ }

    <div className="container-min">
      <ContactBox />
    </div>
  </DefaultLayout>
);