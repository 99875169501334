
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';

const initial_state = {
  isSendingMail: false,
  mailSent: false
};
/*eslint no-useless-escape: "off"*/
const mailregex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export default class extends React.PureComponent {

  state = initial_state;

  contact = (event) => {
    event.preventDefault();

    const {
      email: { value: from = '' },
      body: { value: message = '' }
    } = this.refs;

    if ( ! mailregex.test(from)) {
      alert('Dirección de correo no válida, ingrese un mail válido al que podamos contactarlo');
      return;
    }

    this.setState({
      isSendingMail: true
    });

    axios.post('/api/contact', { from, message })
    .then(response => {
      this.setState({
        isSendingMail: false,
        mailSent: true
      });

      setTimeout(() => {
        this.onClose();
      }, 4000);
    })
    .catch(e => {
      alert('No se pudo enviar el mensaje, intente nuevamente en unos momentos');
      this.setState({
        isSendingMail: false
      });
      console.log(e);
    });
  };

  componentDidMount() {
    if ( ! this.props.isShowingContactModal) {
      this.refs.contactModal.style.display = 'none';
    }
    this.componentDidUpdate({});
  }

  buttonclass = () => `button button-red action-right ${this.state.isSendingMail ? 'loading' : ''}`;

  componentDidUpdate(prevProps) {
    if (this.props.isShowingContactModal && ! prevProps.isShowingContactModal) {
      const { email, body } = this.refs;
      email.value = '';
      body.value = '';
      this.refs.contactModal.style.display = 'flex';
      setTimeout(() => {
        this.refs.contactModal.classList.add('show');
      });
      return;
    }
    if ( ! this.props.isShowingContactModal && prevProps.isShowingContactModal) {
      this.refs.contactModal.classList.remove('show');
      setTimeout(() => {
        this.refs.contactModal.style.display = 'none';
      }, 200);
      return;
    }
  }

  onClose = (event) => {
    if (event) event.preventDefault();

    const { hideContactModal = () => {} } = this.props;

    this.setState({...initial_state});

    hideContactModal();
  }

  classFor = (section) => {
    let className = section;
    if (this.state.mailSent) {
      if (section === 'form') return className;
      return className+' current';
    }

    if (section === 'success-box') return className;
    return className+' current';
  }

  render() {

    return (
      <section ref="contactModal" id="contact-modal" onClick={({target}) => target === this.refs.contactModal && this.onClose()}>
        <section id="contact-dialog">
          <article id="contact-info">
          <p>
              <FontAwesomeIcon className="icon" size="2x" icon={['fas', 'map-marker-alt']} />
              <span>Enrique Tarigo 1335 Edificio Torre Libertad. Oficina 413</span>
            </p>
            <p>
              <FontAwesomeIcon className="icon" size="2x" icon={['fab', 'whatsapp']} />
              <span>092 391 963</span>
            </p>
            <p>
              <FontAwesomeIcon className="icon" size="2x" icon={['fas', 'phone']} />
              <span>2 904 86 38</span>
            </p>
            <p>
              <FontAwesomeIcon className="icon" size="2x" icon={['fas', 'envelope']} />
              <span>contacto@vitech.uy</span>
            </p>
          </article>
          <article id="contact-form">
            <h4>Vitech Soluciones Integrales</h4>

            <div className="form-content">
              <form className={ this.classFor('form') } onSubmit={ this.contact }>
                <h3>Escríbenos y nos pondremos en contacto contigo.</h3>
      
                <input ref="email" type="email" placeholder="Correo electrónico al que te podemos responder" />
                <textarea ref="body" placeholder="Tu mensaje" rows="14"></textarea>
      
                <div className="actions">
                  <button type="button" className="button action-left" onClick={ this.onClose }>CANCELAR</button>
                  <button className={ this.buttonclass() }>ENVIAR <FontAwesomeIcon className="spinner" icon={['fas', 'spinner']} /></button>
                </div>
              </form>
              <article className={ this.classFor('success-box')}>
                <h3>¡Gracias!</h3>
                <p>
                  <FontAwesomeIcon className="icon" icon={['fas', 'check-double']} />
                  <span>Gracias por ponerte en contacto con nosotros.<br />Nos comunicaremos contigo a la brevedad.</span>
                </p>
              </article>
            </div>
          </article>
        </section>

      </section>
    );
  }
}