
import React from 'react';

import { Link } from 'gatsby';

const Menu = ({ links = [] }) => (
  <ul className="navbar-menu">
    { links.map((link, index) => (
      <li key={ index }>
        <Link to={ link.href }>
          { link.label }
        </Link>
      </li>
    )) }
  </ul>
);

export default ({ children, links = [], kind = 'default' }) => (
  <div className={ `navbar navbar-${kind}` }>
    <div className="container">
      { children }

      { links.length ? <Menu links={ links } /> : '' }
    </div>
  </div>
);