
import React from 'react';

import { Link } from 'gatsby';

const serviceDescription = (lines = []) => {
  if (lines && ! Array.isArray(lines)) {
    lines = [lines];
  }

  return lines.map((line, index) => (
    <p key={ index }>{ line }</p>
  ));
};

const serviceStyle = ({background}) => ({ backgroundImage: `url(${background})` });


export default (service) => (
  <article className={`service ${service.alignment || 'justify'} title-${service.titlealignment || 'left'} ${service.titlemiddle && 'autotitle'}`}>
    <header style={ serviceStyle(service) }>
      <h3 className="service-name">{ service.name }</h3>
    </header>

    <section className="service-content">
      { serviceDescription(service.description) }
    </section>

    <footer>
      { service.link && (
        <>
          <Link to={ service.link }>VER MÁS +</Link>
          <Link className="service-fillerLink" to={ service.link }></Link>
        </>
      ) }
    </footer>
  </article>
);