
import React from 'react';

const heroClass = ({description, links}) => ( ! description && ! links.length) ? 'simple' : '';

export default ({ title, description, background, links = [], backgroundPosition = 'center center' }) => (
  <section id="hero" className={ heroClass({description, links}) } style={{ backgroundImage: `url(${background})`, backgroundPosition }}>
    <div className="container">
      <article>
        <h1>{ title }</h1>
        { description && <p>{ description }</p> }

        { links.map(link => link) }
      </article>
    </div>

  </section>
)