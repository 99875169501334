import AgencyPreview from '../../static/img/services/agency-preview.jpg';
import SoftwarePreview from '../../static/img/services/software-preview.jpg';

export default [
{
    link: "/sections/contable",
    name: "Gestión Administrativa Contable",
    titlealignment: 'center',
    alignment: 'center',
    description: [
      "Asesoramiento y consultoría para particulares, pequeñas empresas y Organizaciones de la Sociedad Civil.",
      "Formulación de Proyectos",
      "Gestión Contable",
      "Liquidación de sueldos."
    ],
    background: AgencyPreview
  },
  {
    link: "/sections/software",
    name: "Desarrollo & Diseño Web",
    titlealignment: 'center',
    alignment: 'center',
    description: [
      "Desarrollo aplicaciones web, aplicaciones de gestión, diseño de sitios web presentacionales, páginas corporativoa, tiendas online (ecommerce), inmobiliarias, etc.",
      "Diseño gráfico y branding: construimos en conjunto tu marca para darle personalidad, haciéndola sobresalir sobre la competencia y poder establecer una conexión transparente con el cliente."
    ],
    background: SoftwarePreview
  }
];
